<template>
    <section class="section-titulos-component py-3">
        <div class="row mx-0">
            <div class="col px-0">
                <template v-if="data.tipo == 21">
                    <div class="row mx-0" :class="`${data.parametro == 21 ? 'justify-content-start' : data.parametro == 22 ? 'justify-content-center' : data.parametro == 23 ? 'justify-content-end' : ''}`">
                        <div class="col-auto text-general f-28 f-600 pl-0">
                            {{ data.titulo }}
                        </div>
                    </div>
                </template>
                <template v-if="data.tipo == 22">
                    <div class="row mx-0" :class="`${data.parametro == 21 ? 'justify-content-start' : data.parametro == 22 ? 'justify-content-center' : data.parametro == 23 ? 'justify-content-end' : ''}`">
                        <p class="col-auto px-0 text-general f-28 f-600 pl-0">
                            {{ data.titulo }}
                        </p>
                        <div class="col-12" />
                        <div class="col-auto bg-general" style="width:44px;height:5px;" />
                    </div>
                </template>
                <template v-if="data.tipo == 23">
                    <div class="row mx-0" :class="`${data.parametro == 21 ? 'justify-content-start' : data.parametro == 22 ? 'justify-content-center' : data.parametro == 23 ? 'justify-content-end' : ''}`">
                        <div class="col-auto px-0 d-flex">
                            <h4 class="space-title px-3 text-general f-28 f-600 py-2">
                                {{ data.titulo }}
                                <div class="bg-title" />
                            </h4>
                            <div class="vertical-bar-01" />
                            <div class="vertical-bar-02" />
                            <div class="vertical-bar-03" />
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        data:{
            type:Object,
            default:() => {}
        },
    },
    data(){
        return {
            activeComponent: false
        }
    }
}
</script>
<style lang="scss" scoped>


.space-title{
    min-height: 44px;
    position: relative;
    //background-color: rgb(216, 207, 244);
    display: flex;
    align-items: center;
    justify-content: center;
    .bg-title{
        background-color: var(--color-general);
        opacity: 0.2;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
    }
}
.vertical-bar{
	background-color: var(--color-general);
	opacity: 0.2;
	min-height: 44px;
	width: 10px;
	&-01{
		@extend .vertical-bar;
		margin-left: 5px;
		margin-right: 5px;
		/* right: -16px; */
	}
	&-02{
		@extend .vertical-bar;
		margin-right: 5px;
		/* right: -28px; */
	}
	&-03{
		@extend .vertical-bar;
		margin-right: 5px;
		/* right: -40px; */
	}
}

@media screen and (max-width: 576px){
	.section-titulos-component{
		.space-title{
			font-size: 18px !important;
		}
	}
}

</style>